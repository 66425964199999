<script setup lang="ts">
const { isImpersonating, session } = useAuth()
const { data: workspace } = await useWorkspaceSettings()
</script>
<template>
  <div
    class="relative isolate flex min-h-svh w-full bg-slate-100 max-lg:flex-col"
  >
    <DemoBadge v-if="workspace?.demo" />
    <TopNavigation />
    <SidebarNavigation />
    <main
      id="main-content"
      class="flex flex-1 flex-col lg:min-w-0 lg:pt-2 lg:pr-2 lg:pb-2 lg:transition-all lg:duration-200 lg:ease-in-out"
    >
      <div
        class="relative z-10 grow overflow-y-hidden rounded-lg bg-slate-50 shadow-xs ring-1 ring-slate-950/5"
      >
        <div class="@container/main mx-auto w-full">
          <NuxtPage />
        </div>
      </div>
    </main>
    <NotificationToast />
    <ImpersonatingBadge
      v-if="session && isImpersonating"
      :email="session.user.email"
    />
    <DeveloperToolbox />
  </div>
</template>
